












































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import BookAppointment from "@/doctor-app/components/BookAppointment.vue";
import ProfileWidget from "@/common-app/components/ProfileWidget.vue";
import DoctorService from "../../service/DoctorService";
import DoctorReviewService from "../../service/DoctorReviewService";
import { IDoctorDetail } from "../../models/DoctorDetail";
import { ISpecialization } from "../../../common-app/models/Specialization";
import { IDoctorSpecialization } from "../../models/DoctorSpecialization";
import { IDoctorExprience } from "../../models/DoctorExprience";
import { IDoctorEducation } from "../../models/DoctorEducation";
import { IDoctorAward } from "../../models/DoctorAward";
import { IDoctorChamber } from "../../models/DoctorChamber";
import { IDoctorReview } from "../../models/DoctorReview";
import AlertService from "../../../common-app/service/AlertService";

@Component({
  components: {
    ProfileWidget,
    BookAppointment,
  },
})
export default class Details extends Vue {
  public revirew: IDoctorReview = {
    rating: 0,
    isFavourite: false,
  } as IDoctorReview;
  public isShowAppoitment: boolean = false;
  public detail: IDoctorDetail = {} as IDoctorDetail;
  public specializationList: ISpecialization[] = [];
  public doctorSpecializationList: IDoctorSpecialization[] = [];
  public doctorExperienceList: IDoctorExprience[] = [];
  public doctorEducationList: IDoctorEducation[] = [];
  public doctorAwardList: IDoctorAward[] = [];
  public doctorChambers: IDoctorChamber[] = [];
  public profile: any = {};
  public oldRating: number;

  public get doctorSpecialization(): string[] {
    return this.doctorSpecializationList.map((s) => {
      return s.specialisationName;
    });
  }

  public get exprience(): string[] {
    return this.doctorExperienceList.map((s) => {
      return s.hospitalName;
    });
  }

  public get education(): string[] {
    return this.doctorEducationList.map((s) => {
      return s.title;
    });
  }

  //   SELECT u.*, d.*, ex.*, edu.*, esp.* FROM SecurityUsers u
  // LEFT JOIN Doctor.Details d on u.Id = d.UserId
  // LEFT JOIN Doctor.Experiences ex on u.Id = ex.UserId
  // LEFT JOIN Doctor.Educations edu on u.Id = edu.UserId
  // LEFT JOIN Doctor.Specializations esp on u.Id = esp.UserId
  created() {
    DoctorService.GetDetails(Number(this.$route.params.id || 0)).then((s) => {
      this.detail = s.data.doctorDetails || ({} as IDoctorDetail);
      this.specializationList = s.data.specializations || [];
      this.doctorSpecializationList = s.data.doctorSpecializations || [];
      this.doctorExperienceList = s.data.doctorExperiences || [];
      this.doctorEducationList = s.data.doctorEducations || [];
      this.doctorAwardList = s.data.doctorAwards || [];
      this.doctorChambers = s.data.doctorChambers || [];
      this.profile = s.data.profile || {};
    });

    DoctorReviewService.GetReviewByCurrentUser(Number(this.$route.params.id || 0)).then((res) => {
      this.revirew = res.data.result || this.revirew;
      this.revirew = Object.assign(this.revirew, {
        doctorId: Number(this.$route.params.id || 0),
      });
      this.oldRating = this.revirew.rating;
    });

    DoctorReviewService.getDoctorReview(Number(this.$route.params.id || 0)).then((res) => {
      this.$store.dispatch("AddRating", res.data.result.rating || 0);
      this.$store.dispatch("AddFavourite", res.data.result.favourite || 0);
    });
  }
  opencloseModal(value: any) {
    this.isShowAppoitment = value;
  }

  ratingSubmit() {
    if (this.revirew.id > 0) {
      DoctorReviewService.update(this.revirew).then((res) => {
        this.revirew = res.data.result || this.revirew;
        let storerating = this.$store.getters.rating - this.oldRating;
        this.$store.dispatch("AddRating", storerating + this.revirew.rating);
        this.oldRating = this.revirew.rating;
        AlertService.sendMessage({ status: true, message: "Rating Add Successfully" });
      });
    } else {
      DoctorReviewService.insert(this.revirew).then((res) => {
        this.revirew = res.data.result || this.revirew;
        let storerating = this.$store.getters.rating;
        this.$store.dispatch("AddRating", storerating + this.revirew.rating);
        //alert("Rating Add Success");
        AlertService.sendMessage({ status: true, message: "Rating Add Successfully" });
      });
    }
  }
  handleChange(e: any) {
    DoctorReviewService.update(Object.assign(this.revirew, { isFavourite: e.target.checked })).then((res) => {
      this.revirew = res.data.result || this.revirew;
      let fav = this.$store.getters.favourite;
      if (e.target.checked) {
        this.$store.dispatch("AddFavourite", fav + 1);
        // alert("Fevourite Add Success");
        AlertService.sendMessage({ status: true, message: "Fevourite Add Success" });
      } else {
        this.$store.dispatch("AddFavourite", fav - 1);
        AlertService.sendMessage({ status: false, message: "Fevourite remove" });
      }
    });
  }
}
