


































































































































































































































































































































































import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import { DayOfWeek } from "../../helper/Enums";
import { defer } from "rxjs";
import DoctorService from "../service/DoctorService";
import DoctorAppointmentService from "../service/DoctorAppointmentService";
import DoctorScheduleService from "@/doctor-app/service/DoctorScheduleService";
import UniversalService from "../../common-app/service/UniversalService";
import { IDoctorChamber } from "../models/DoctorChamber";
import { IDoctorChamberSchedule } from "../models/DoctorChamberSchedule";
import { IDoctorAppointment } from "../models/DoctorAppointment";
import { IDoctorSchedule } from "@/doctor-app/models/DoctorSchedule";
import GuestLogin from "@/doctor-app/components/GuestLogin.vue";
import AuthUser from "../../global-config/AuthUser";
import AuthService from "@/website/services/AuthService";
import { RegViewModel } from "@/website/models/RegViewModel";
import AlertService from "../../common-app/service/AlertService";
import TermsConditions from "./TermsConditions.vue";
@Component({
  components: {
    GuestLogin,
    TermsConditions,
  },
})
export default class BookAppointment extends Vue {
  @Prop({ default: 0, required: true }) doctorId!: number;
  @Prop({ default: 0, required: true }) paramChamberId!: number;
  public currentDate: Date = new Date();
  public isShow: boolean = false;
  public DayOfWeek = DayOfWeek;
  public chambers: IDoctorChamber[] = [];
  public schedules: IDoctorSchedule[] = [];
  public chamberScheduleList: IDoctorChamberSchedule[] = [];
  public scheduleList: IDoctorChamberSchedule[] = [];
  public chamberSchedule: IDoctorChamberSchedule = {} as IDoctorChamberSchedule;
  public doctorAppoitment: IDoctorAppointment = {} as IDoctorAppointment;
  public currentTab = "#step1";
  public enableStep2: boolean = false;
  public enableStep3: boolean = false;
  public showVerificationTab: boolean = false;
  public showConfirmationTab: boolean = false;
  public verificationCode: string = "";
  public currentIndex: number = -1;
  public submited: boolean = false;
  public enableDatepicker: boolean = false;
  public isLoggedIn: boolean = false;
  public isShowGuestLogin: boolean = true;
  public isConfirmed: boolean = false;
  public sendMessageCount = 1;
  public chamberId: number = 0;
  public scheduleId: number;
  public selectedTime: any;
  public user = AuthUser.getAuthUser();
  public userReg: RegViewModel = {} as RegViewModel;
  public appointmentWithGuest: boolean = false;
  public allAvaliableSchedules: IDoctorSchedule[] = [];
  public showTermsConditions: boolean = false;
  created() {
    this.doctorAppoitment.chamberId=this.paramChamberId;
    this.chamberId=this.paramChamberId;
    this.onChangeSchedule();
    const dict = {
      custom: {
        ChamberName: {
          required: "The chamber name is required.",
        },
        patientName: {
          required: "Patient name is required.",
        },
        Phone: {
          required: "Phone number is required.",
          numeric: "phone number can only contain numeric value.",
          min: "Phone number should be minimum 11 charecter.",
          max: "Phone number cab be maximum 11 charecter.",
        },
        dob: {
          required: "Date of birth is required",
          beforeCurrentDate: "End date must be before current date.",
        },
      },
    };
    this.$validator.localize("en", dict);
    this.isLoggedIn = AuthUser.isLoggednIn() || false;
    DoctorService.GetChambers(this.doctorId).then((res) => {
      this.chambers = res.data;
      // console.log(this.chambers);
      //alert(this.chambers);
    });
    if (this.isLoggedIn) {
      this.isShow = true;
    }
    if (this.isLoggedIn) {
      this.doctorAppoitment.patientName = this.user.firstName + " " + this.user.lastName;
      this.doctorAppoitment.phone = this.user.phone;
    }
  }
  @Emit("close")
  close() {
    this.isShow = false;
    return this.isShow;
  }
  findSchedule(value: number) {
    if (this.chamberId < 1) {
      AlertService.sendMessage({ status: false, message: "Please select Chamber" });
      return;
    }
    this.currentDate = new Date(this.currentDate.setDate(this.currentDate.getDate() + value));
    let day = this.currentDate.getDay();
    this.scheduleList = this.chamberScheduleList.filter((s) => s.day == day) || [];
    this.onChangeSchedule();
  }
  // onChange(event: any) {
  //   DoctorService.GetChamberSchedule(Number(event.target.value)).then((res) => {
  //     this.scheduleList = [];
  //     this.currentIndex = -1;
  //     this.chamberScheduleList = (res.data as IDoctorChamberSchedule[]).map((item) => Object.assign({}, item, { isClick: false }));
  //   });
  // }
  onChangeSchedule() {
    this.allAvaliableSchedules = [];
    DoctorScheduleService.GetScheduleListByDate(this.chamberId, this.currentDate).then((res) => {
      this.schedules = [];
      if (res.data.result) {
        this.schedules = res.data.result;
        this.allAvaliableSchedules = this.schedules.filter((s) => s.isBooked == false) || [];
        //console.log(this.schedules);
      }
    });
  }
  onChange(event: any) {
    this.chamberId = event.target.value;
    this.onChangeSchedule();
  }
  sendCode() {
    DoctorAppointmentService.reSendCode(this.doctorAppoitment.id).then((res) => {
      if (this.sendMessageCount <= 3) {
        if (res.data) {
          AlertService.sendMessage({ status: true, message: "code send success" });
        } else {
          AlertService.sendMessage({ status: false, message: "code send error" });
        }
      }
      this.sendMessageCount++;
    });
  }
  add(schedule: IDoctorChamberSchedule, index: number) {
    this.currentIndex = index;
    //AlertService.sendMessage({ status: true, message: this.currentIndex });
    this.chamberSchedule = schedule;
    // Vue.set(
    //   this.scheduleList,
    //   index,
    //   Object.assign(schedule, { isClick: true })
    // );
    // if (schedule.isClick) {
    //   this.scheduleList.splice(
    //     index,
    //     1,
    //     Object.assign({}, schedule, { isClick: false })
    //   );
    //   let newSchedule = this.doctorAppoitment.doctorAppointmentDetails.filter(
    //     s => s.scheduleId != schedule.id
    //   );
    //   this.doctorAppoitment.doctorAppointmentDetails = newSchedule;
    // } else {
    //   this.scheduleList.splice(
    //     index,
    //     1,
    //     Object.assign({}, schedule, { isClick: true })
    //   );
    //   let data = {
    //     startTime: schedule.startTime,
    //     endTime: schedule.endTime,
    //     id: 0,
    //     appointmentId: 0,
    //     scheduleId: schedule.id
    //   };
    //   this.doctorAppoitment.doctorAppointmentDetails.push(
    //     Object.assign({}, data)
    //   );
    // }
    // this.doctorAppoitment = Object.assign({}, this.doctorAppoitment);
  }
  getScheduleId(value: any, value2: any, index: number) {
    this.scheduleId = value;
    this.currentIndex = index;
    this.selectedTime = value2;
    //alert(this.scheduleId);
  }
  goToVerification(scope: string) {
    this.$validator.validateAll(scope).then((valid) => {
      if (valid) {
        if (scope == "form-2") {
          this.submited = true;
          AlertService.sendMessage({ status: true, message: this.$store.getters.verifycode });
          if (this.$store.getters.verifycode === this.verificationCode) {
            AuthService.verify({
              userId: this.$store.getters.userId,
              code: this.verificationCode,
            })
              .then((response) => {
                if (response.data) {
                  this.$store.dispatch("addVerify", {
                    userId: this.$store.getters.userId,
                    verifycode: this.verificationCode,
                  });
                  AuthUser.setToken(response.data);
                  this.submited = true;
                  this.doctorAppoitment.chamberSchedulesId = this.scheduleId;
                  this.doctorAppoitment.entryBy = this.$store.getters.userId;
                  DoctorAppointmentService.insert(
                    Object.assign(this.doctorAppoitment, {
                      appointmentDate: this.currentDate,
                      doctorId: this.doctorId,
                    })
                  ).then((res) => {
                    if (res.data.status) {
                      this.submited = false;
                      this.doctorAppoitment = res.data.result;
                      this.enableStep2 = true;
                      this.showVerificationTab = true;
                      this.submited = false;
                      AlertService.sendMessage({ status: true, message: "appointment success" });
                      this.goToCompletion();
                    } else {
                      this.submited = false;
                      AlertService.sendMessage({ status: false, message: "Time Slot does not exists" });
                      //AlertService.sendMessage("success");
                    }
                  });
                  //
                  //this.$router.push({ path: "registration-confirmation" });
                } else {
                  this.submited = false;
                  AlertService.sendMessage({ status: false, message: "code invalid" });
                }
              })
              .catch((dd) => {
                // debugger;
                var sf = "";
              });
          } else {
            this.submited = false;
            AlertService.sendMessage({ status: false, message: "code invalid" });
          }
        }
        if (scope == "form-1" && !this.isLoggedIn) {
          this.submited = true;
          /*
          UniversalService.sendSms(this.doctorAppoitment.phone.toString()).then((res) => {
            // this.doctorAppoitment.verificationCode = res.data;
            this.enableStep2 = true;
            this.showVerificationTab = true;
            this.submited = false;
          });
            */
          //this.submitted = true;
          this.userReg.firstName = this.doctorAppoitment.patientName;
          this.userReg.phone = this.doctorAppoitment.phone;
          this.userReg.password = "1234567";
          AuthService.registration(this.userReg)
            .then((response) => {
              let result = response.data as any;
              if (result.status) {
                // console.log(result.result.securityVerification.code);
                this.$store.dispatch("addVerify", {
                  userId: result.result.user.id,
                  verifycode: result.result.securityVerification.code,
                });
                //this.$router.push({ path: "registration-verify" });
                this.enableStep2 = true;
                this.showVerificationTab = true;
                this.submited = false;
                this.isLoggedIn = true;
              } else {
                //this.submitted = false;
                if (result.messageType == "unique") {
                  this.$validator.errors.add({
                    field: "phone",
                    msg: result.message,
                  });
                } else {
                  //AlertService.sendMessage(result.message);
                }
              }
            })
            .catch((error) => {
              // this.submitted = false;
            });
          //zh
        } else {
          // if (this.doctorAppoitment.verificationCode.toString() !== this.verificationCode) {
          //   alert("code not valid");
          //   this.submited = false;
          //   return;
          // }
          //this.goToCompletion();
          this.submited = true;
          this.doctorAppoitment.chamberSchedulesId = this.scheduleId;
          DoctorAppointmentService.insert(
            Object.assign(this.doctorAppoitment, {
              appointmentDate: this.currentDate,
              doctorId: this.doctorId,
            })
          ).then((res) => {
            if (res.data.status) {
              this.submited = false;
              //alert("appointment success");
              this.doctorAppoitment = res.data.result;
              this.enableStep2 = true;
              this.showVerificationTab = true;
              this.submited = false;
              // AlertService.sendMessage({ status: true, message: "appointment success" });
              this.goToCompletion();
            } else {
              this.submited = false;
              AlertService.sendMessage({ status: false, message: "Time Slot does not exists" });
              //AlertService.sendMessage("success");
            }
          });
          // UniversalService.sendSms(this.doctorAppoitment.phone.toString()).then(
          //   res => {
          //     this.doctorAppoitment.verificationCode = res.data;
          //     this.enableStep2 = true;
          //     this.showVerificationTab = true;
          //     this.submited = false;
          //   }
          // );
        }
        // else {
        //   if (this.doctorAppoitment.code.toString() !== this.verificationCode) {
        //     alert("code not valid");
        //     this.submited = false;
        //     return;
        //   }
        //   this.submited = true;
        //   this.doctorAppoitment.code = this.verificationCode;
        //   DoctorAppointmentService.varify(this.doctorAppoitment).then(res => {
        //     this.submited = false;
        //     alert("appointment success");
        //     this.goToCompletion();
        //   });
        // }
      }
    });
  }
  goToCompletion() {
    this.enableStep3 = true;
    this.enableStep2 = false;
    this.showConfirmationTab = true;
    this.showVerificationTab = false;
  }
  showDatePicker() {
    this.enableDatepicker = !this.enableDatepicker;
  }
  closeGuestLogin() {
    this.isShowGuestLogin = false;
    // this.isLoggedIn = true;
    // this.isShow = false;
    //this.close();
    if (AuthUser.isLoggednIn()) {
      this.isShowGuestLogin = false;
      this.isShow = true;
      this.isLoggedIn = true;
      this.user = AuthUser.getAuthUser();
      this.doctorAppoitment.patientName = this.user.firstName + " " + this.user.lastName;
      this.doctorAppoitment.phone = this.user.phone;
    } else {
      this.close();
    }
  }
  loginWithGuest() {
    this.isShowGuestLogin = false;
    this.isShow = true;
    this.appointmentWithGuest = true;
    this.isLoggedIn = true;
    if (this.isLoggedIn) {
      this.user = AuthUser.getAuthUser();
      this.doctorAppoitment.patientName = this.user.firstName + " " + this.user.lastName;
      this.doctorAppoitment.phone = this.user.phone;
    }
  }
  closeTermsConditions() {
    this.showTermsConditions = false;
  }
}
